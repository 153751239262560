
import { Options, Vue } from "vue-property-decorator";
import DefaultLayout from "@/layout/DefaultLayout.vue";

@Options({
  components: { DefaultLayout },
})
export default class SetUpMandateFail extends Vue {
  private failMessage = "Mandate import failed";
}
